import { format, parseISO, fromUnixTime } from 'date-fns';

export function formatDate(value, formatString = 'yyyy-MM-dd'): string {
  if (typeof value === 'number' || value instanceof Date) {
    return format(value, formatString, { locale: window.__pjas_date_locale[window.__pjas_locale] });
  }

  return format(parseISO(value), formatString, { locale: window.__pjas_date_locale[window.__pjas_locale] });
}

export function formatTimestampToDate(timestamp) {
  return fromUnixTime(timestamp);
}

export function formatDateTime(value, formatString = 'yyyy-MM-dd HH:ii:ss'): string {
  return formatDate(value, formatString);
}

function dateFormatFilter(value, formatString = 'yyyy-MM-dd'): string {
  return formatDate(value, formatString);
}

export default dateFormatFilter;
