<template>
  <div class="row">
    <div class="col-6">
      <LiFormInput
        v-model.number="internalBudget"
        type="currency"
        label="Total Budget"
        :is-required="true"
        class="col-12"
        :class="[internalBudget < minimumTotalBudget ? 'is-invalid' : '']"
        :locale="defaultLocale"
        :currency="computedCurrency"
      >
      </LiFormInput>
    </div>
    <div class="col-6">
      <LiFormInput
        v-model.number="internalDaily"
        type="currency"
        label="Daily"
        :is-required="true"
        class="col-12"
        :currency="computedCurrency"
        :locale="defaultLocale"
        :class="[minimumDailyBudget > internalDaily ? 'is-invalid' : '']"
      >
        >
      </LiFormInput>
    </div>
    <div class="col-12 mt-2">
      <input
        v-model="internalDaily"
        type="range"
        class="form-range mb-2"
        :min="minimumDailyBudget"
        :max="minimumDailyBudget * 5"
        step="500"
      />
    </div>
    <div class="col-12 text-muted mt-2">
      <!-- TODO replace with translation -->
      <small
        >Please specify the budget for your campaign. With a running time of <strong>{{ numberOfDays }}</strong> days,
        the minimum budget is <strong>{{ minimumTotalBudgetFormatted }}</strong></small
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, inject } from 'vue';
import type { WritableComputedRef, Ref } from 'vue';

import { parseISO } from 'date-fns';

import { FormInput as LiFormInput } from '@prospective/lithium';

import { defaultCurrency, defaultDailyBudget, defaultLocale } from '../../../config/app';
import type { OrderCreationData } from './orderCreation';

const orderCreation = inject<Ref<OrderCreationData>>('orderCreation') as Ref<OrderCreationData>;
const computedCurrency = inject<string>('computedCurrency');

const emit = defineEmits(['update:budget']);
const props = defineProps({
  startdate: { type: String, required: true },
  enddate: { type: String, required: true },
  budget: { type: Number, required: true },
});

const internalBudget = computed({
  get() {
    return props.budget ?? 0;
  },
  set(value) {
    emit('update:budget', value);
  },
});

// calculate the number of days available
const numberOfDays = computed(() => {
  let start = parseISO(props.startdate);
  let end = parseISO(props.enddate);

  return Math.round((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
});

const internalDaily: WritableComputedRef<number> = computed({
  get(): number {
    return (props.budget ?? 0) / Math.max(1, numberOfDays.value);
  },
  set(value: number): void {
    emit('update:budget', value * numberOfDays.value);
  },
});

// inital default to 50
if (internalDaily.value < 1) {
  internalDaily.value = defaultDailyBudget;
}

const minimumDailyBudget = computed(() => {
  // calculate the actual minimum daily budget
  // which is the minimum total budget divided by the number of days against the minimum daily budget
  let minimum = orderCreation.value.minimum_budget_total / numberOfDays.value;
  return Math.floor(Math.max(minimum, orderCreation.value.minimum_budget_daily));
});

// handle the minimum budets
const minimumTotalBudget = computed(() => {
  let minimum = orderCreation.value.minimum_budget_daily * numberOfDays.value;
  minimum = Math.max(minimum, orderCreation.value.minimum_budget_total);
  return minimum;
});

// watch minimumTotalBudget and update internalBudget if it changes
watch(minimumTotalBudget, (value) => {
  if (internalBudget.value < value) {
    internalBudget.value = value;
  }
});

const minimumTotalBudgetFormatted = computed(() => {
  return (minimumTotalBudget.value / 100).toLocaleString(defaultLocale, {
    style: 'currency',
    currency: defaultCurrency,
  });
});
</script>
